import * as Tooltip from '@radix-ui/react-tooltip';
import { useT } from '@transifex/react';

import { getCurrencySymbol, useLocalizedCurrencyFormatter } from 'src/utils/currency';

type Club = {
  currencyCode: string;
  weekdayPrice: number;
  weekendPrice: number;
};

export const ClubPrices = ({ club, locale }: { club: Club; locale: string }) => {
  const t = useT();

  const currencyFormatter = useLocalizedCurrencyFormatter(club.currencyCode, locale);
  const prices = [...new Set([club.weekdayPrice, club.weekendPrice])].filter((price) => !!price);
  const currencySymbol = getCurrencySymbol(currencyFormatter);

  const getDisplayPrice = (prices: number[]) => {
    if (prices.length > 1 && prices[0].toFixed() !== prices[1].toFixed()) {
      return `${prices[0].toFixed()}-${prices[1].toFixed()}`;
    }
    return prices[0].toFixed();
  };

  // TODO use a proper internationalization method for price range
  // Intl.NumberFormat.fomatRange only exists in Node for now
  // and adobe "@internationalized/number" polifyll gives different results than the node implementation
  return (
    <>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div className="min-w-max text-base font-bold md:text-lg" aria-hidden="true">
            {currencySymbol}
            {getDisplayPrice(prices)}
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="z-50 select-none rounded-sm bg-black/80 px-4 py-2 leading-none text-white shadow-2 will-change-[transform,opacity] data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade"
            sideOffset={10}
          >
            {t('Average weekday and weekend prices')}
            <Tooltip.Arrow className="fill-black/80" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>

      <div className="sr-only">
        {prices.length > 1 &&
          t('Average weekday price of {weekPrice} and weekend price of {weekendPrice}', {
            weekPrice: currencyFormatter.format(prices[0]),
            weekendPrice: currencyFormatter.format(prices[1]),
          })}
        {prices.length === 1 &&
          t('Average price of {price}', { price: currencyFormatter.format(prices[0]) })}
        {prices.length === 0 && t('No known average price')}
      </div>
    </>
  );
};
