import { useRef } from 'react';

import { ButtonLink } from '@lightspeed/golf-b2c-design-system';
import { useT } from '@transifex/react';
import Image from 'next/image';
import { QRCodeSVG } from 'qrcode.react';

import { AppStoreUrls } from 'src/constants';

type MobileAppBannerProps = {
  userAgent: string | undefined;
};

const getMobileOperatingSystem = (userAgent: string | undefined) => {
  if (userAgent) {
    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone/i.test(userAgent)) {
      return 'iOS';
    }
  }
  return null;
};

export const MobileAppBanner = ({ userAgent }: MobileAppBannerProps) => {
  const mobileOs = useRef(getMobileOperatingSystem(userAgent));
  const t = useT();
  return (
    <div
      id="chronogolf-mobile-app"
      className="mt-10 bg-primary-lighter px-4 py-10 md:px-8 lg:mt-12"
    >
      <div className="flex items-center">
        <div className="mx-auto flex items-center">
          <Image
            className="shrink-0"
            src="/assets/images/app-icon.svg"
            alt={t('Chronogolf Mobile App')}
            width={60}
            height={60}
          />
          <div className="ml-4 flex flex-col items-start">
            <p
              className={`${
                mobileOs.current ? 'mb-4' : ''
              }text-lg font-bold leading-relaxed md:max-w-[320px] lg:max-w-[240px] xl:max-w-[360px]`}
            >
              {t(
                'Like what you see? Download our mobile app and enjoy more features catered to you.'
              )}
            </p>
            {mobileOs.current && (
              <ButtonLink
                intent="secondary"
                to={
                  mobileOs.current === 'iOS'
                    ? `${AppStoreUrls.IOS}&ct=marketplace_index_banner`
                    : `${AppStoreUrls.ANDROID}&hl=en&utm_source=chronogolf&utm_campaign=marketplace_index_banner`
                }
                target="_blank"
                rel="noreferrer"
              >
                {t('Get the app')}
              </ButtonLink>
            )}
          </div>
          {!mobileOs.current && (
            <div className="ml-6 flex flex-col items-center">
              <QRCodeSVG
                value={`${process.env.MONOLITH_BASE_URL}/#chronogolf-mobile-app`}
                size={128}
                includeMargin={false}
                className="bg-white p-3"
              />
              <span className="mt-1 text-sm">{t('Scan the QR Code')}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
