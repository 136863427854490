import { useT } from '@transifex/react';
import Image from 'next/image';

export const WhyChronogolf = () => {
  const t = useT();

  return (
    <div className="mt-10 bg-secondary-lighter px-4 py-10 md:px-8 lg:mt-12">
      <div className="flex flex-col xl:container md:items-center">
        <h2 className="mb-2 heading-md md:heading-lg">{t('Why Chronogolf?')}</h2>
        <h3 className="mb-10 text-grey-700 md:mb-8">
          {t('Go with a platform trusted by more than 2 million golfers and 1,800 courses.')}
        </h3>
        <div className="flex flex-col space-y-8 px-4 md:flex-row md:space-x-4 md:space-y-0 md:px-0 lg:space-x-6">
          <div>
            <Image
              src="/assets/images/amazing-deals.svg"
              alt={t('Amazing deals')}
              width={80}
              height={80}
              className="mx-auto"
            />
            <div className="mb-2 mt-4 text-center uppercase heading-xs">{t('Amazing deals')}</div>
            <div className="text-center">
              {t(
                'Find amazing deals and special offers wherever you choose to tee it up with our 1,800+ partner courses.'
              )}
            </div>
          </div>
          <div>
            <Image
              src="/assets/images/discover_courses.svg"
              alt={t('Discover courses')}
              width={80}
              height={80}
              className="mx-auto"
            />
            <div className="mb-2 mt-4 text-center uppercase heading-xs">
              {t('Discover courses')}
            </div>
            <div className="text-center">
              {t('Explore golf courses in trending destinations and all over the world.')}
            </div>
          </div>
          <div>
            <Image
              src="/assets/images/booking-extras.svg"
              alt={t('No booking fees')}
              width={80}
              height={79}
              className="mx-auto"
            />
            <div className="mb-2 mt-4 text-center uppercase heading-xs">
              {t('Add booking extras online')}
            </div>
            <div className="text-center">
              {t(
                'Pay online and add booking extras before your round. Spend less time checking in and more time golfing!'
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
