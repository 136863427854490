import { Fragment, useRef, useState } from 'react';

import { ArrowRightIcon, ButtonLink } from '@lightspeed/golf-b2c-design-system';
import { useT } from '@transifex/react';
import { AxiosError } from 'axios';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';

import { Destination, getTrendingDestination } from 'src/api/destination';
import { Club, getNearbyClubs } from 'src/api/nearby-clubs';
import { ClubCard } from 'src/components/ClubCard';
import { ClubsAndLocationsSearch } from 'src/components/ClubsAndLocationsSearch';
import { MobileAppBanner } from 'src/components/Home/MobileAppBanner';
import { TrendingDestinations } from 'src/components/Home/TrendingDestinations';
import { WhyChronogolf } from 'src/components/Home/WhyChronogolf';
import { convertExecTimeToServerTiming } from 'src/utils/analytics';
import { allowAds, getAdId, IndexGoogleAdsSlot, initAds } from 'src/utils/google-ads';
import { initTx, txProps } from 'src/utils/i18n';
import { getIPFromRequest } from 'src/utils/ip';
import { reportError } from 'src/utils/reporting';
import { getPagesMetas } from 'src/utils/seo';
import { getPageUrl } from 'src/utils/urls';

const AdSlot = dynamic(() => import('src/components/AdSlot').then((m) => m.AdSlot), { ssr: false });

type indexProps = {
  txProps: txProps;
  clubs: Club[];
  userAgent: string | undefined;
  pageUrl: string;
};

export const getServerSideProps: GetServerSideProps<indexProps> = async ({
  locale,
  defaultLocale,
  req,
  res,
  resolvedUrl,
}) => {
  const execTimes = [];
  const txStart = process.hrtime();
  const txProps: txProps = await initTx(locale, defaultLocale);
  execTimes.push({ task: 'tx', duration: process.hrtime(txStart) });
  const clubsStart = process.hrtime();
  const ips = getIPFromRequest(req);
  let clubs: Club[] = [];
  try {
    clubs = await getNearbyClubs(ips);
  } catch (e) {
    reportError(e as AxiosError);
  }
  execTimes.push({ task: 'clubs', duration: process.hrtime(clubsStart) });

  res.setHeader('Server-Timing', execTimes.map(convertExecTimeToServerTiming).join(' '));

  return {
    props: {
      clubs,
      txProps,
      userAgent: req.headers['user-agent'],
      pageUrl: getPageUrl({ host: req.headers.host, path: resolvedUrl }),
    },
  };
};

export default function IndexPage({
  clubs,
  userAgent,
  pageUrl,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const t = useT();
  const [gptLoaded, setGptLoaded] = useState(false);
  const adsInitialized = useRef(false);

  // This is to avoid define the slot multiple time
  if (gptLoaded && !adsInitialized.current) {
    initAds(IndexGoogleAdsSlot);
    adsInitialized.current = true;
  }

  const indexLeaderboardId = getAdId(IndexGoogleAdsSlot, 'index_leaderboard');
  const indexBottombannerId = getAdId(IndexGoogleAdsSlot, 'index_bottombanner');
  const indexMobileGridId = getAdId(IndexGoogleAdsSlot, 'index_mobileclubsgrid');

  const trendingDestinationsList: string[] = ['Ontario', 'Quebec', 'Florida', 'California'];
  const indexTrendingDestinations: Destination[] = getTrendingDestination(trendingDestinationsList);

  const seoMetas = {
    siteName: t('Chronogolf'),
    title: `${t('Best Golf Courses, Discounted Tee Times | Chronogolf')}`,
    description: t('The easiest way to book your next teetime in a click'),
    canonical: 'https://www.chronogolf.com',
    image: '/assets/images/ios-icons/apple-touch-icon.png',
    pageUrl,
  };

  return (
    <>
      {allowAds() && (
        <Script
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
          strategy="lazyOnload"
          onLoad={() => setGptLoaded(true)}
        />
      )}
      <Head>{getPagesMetas(seoMetas)}</Head>
      <div className="flex flex-col items-center bg-primary px-4 py-10 text-white md:px-8">
        <h1 className="mb-2 flex flex-col text-center xl:mb-3">
          <span className="mb-2 page-title-s md:page-title-md lg:page-title-lg">
            {t("Let's golf!")}
          </span>
          <span className="mb-6 text-base lg:text-xl">{t('Find your next favorite course.')}</span>
        </h1>
        <ClubsAndLocationsSearch wrapperClassName="mx-auto md:max-w-[536px]" />
      </div>
      <div className="mt-10 px-4 xl:container md:px-8 lg:mt-12">
        <div className="mb-6 flex items-center justify-between lg:mb-8">
          <h2 className="heading-md lg:heading-lg">{t('Golf courses near you')}</h2>
          <div className="hidden lg:flex">
            <ButtonLink
              intent="text"
              to={`${process.env.NEXT_BASE_URL}/clubs`}
              data-gtm-id="view-more-courses-cta"
            >
              <span>{t('View more courses')}</span>
              <ArrowRightIcon width={24} height={24} />
            </ButtonLink>
          </div>
        </div>
        {clubs && (
          <div
            data-gtm-id="index-nearby-section"
            className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          >
            {clubs?.map((club, i) => (
              <Fragment key={club.uuid}>
                {i === 4 && gptLoaded && indexMobileGridId && (
                  <AdSlot
                    id={indexMobileGridId}
                    className="container mx-auto mb-8 flex justify-center md:hidden"
                  />
                )}

                <ClubCard
                  club={club}
                  prioritizeImageRendering={i < 7}
                  className={i === 8 ? 'hidden md:hidden lg:block xl:hidden' : ''}
                />
              </Fragment>
            ))}
          </div>
        )}
        {!clubs && <p>{t('There was an error while fetching clubs')}</p>}
        <div className="mx-auto mt-8 flex md:max-w-md lg:hidden">
          <ButtonLink
            intent="primary"
            block
            to={`${process.env.NEXT_BASE_URL}/clubs`}
            data-gtm-id="view-more-courses-cta"
          >
            <span>{t('View more courses')}</span>
            <ArrowRightIcon width={24} height={24} />
          </ButtonLink>
        </div>
      </div>
      {gptLoaded && indexLeaderboardId && (
        <AdSlot
          id={indexLeaderboardId}
          className="container mx-auto mt-10 flex justify-center lg:mt-12"
        />
      )}
      <WhyChronogolf />
      <div
        data-gtm-id="index-trending-destination-section"
        className="mt-10 px-4 py-10 xl:container md:px-8 lg:mt-12 lg:py-12"
      >
        <div className="mb-6 flex items-center justify-between lg:mb-8">
          <h2 className="heading-md lg:heading-lg">{t('Trending golf destinations')}</h2>
          <div className="hidden lg:flex">
            <ButtonLink intent="text" to="/locations" data-gtm-id="view-more-courses-cta">
              <span>{t('View all golf destinations')}</span>
              <ArrowRightIcon width={24} height={24} />
            </ButtonLink>
          </div>
        </div>
        <TrendingDestinations destinations={indexTrendingDestinations} />
        <div className="mx-auto mt-8 flex md:max-w-md lg:hidden">
          <ButtonLink intent="primary" block to="/locations" data-gtm-id="view-more-courses-cta">
            <span>{t('View all golf destinations')}</span>
            <ArrowRightIcon width={24} height={24} />
          </ButtonLink>
        </div>
      </div>
      {gptLoaded && indexBottombannerId && (
        <AdSlot
          id={indexBottombannerId}
          className="container mx-auto mt-10 flex justify-center lg:mt-12"
        />
      )}
      <MobileAppBanner userAgent={userAgent} />
    </>
  );
}
