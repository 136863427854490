import { ReactNode } from 'react';

type TagProps = {
  children: ReactNode;
  className?: string;
  color?: 'black' | 'tertiary';
};

const allowedBgColor = { black: 'bg-black', tertiary: 'bg-tertiary-2' };

export const Tag = ({ children, className, color = 'black' }: TagProps) => {
  return (
    <div
      className={`flex items-center rounded-sm px-2 py-1 text-center text-white ${className} ${allowedBgColor[color]} `}
    >
      {children}
    </div>
  );
};
