import { Fragment } from 'react';

import { DealIcon, tokens } from '@lightspeed/golf-b2c-design-system';
import { useT } from '@transifex/react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Tag } from 'src/components/ui/Tag';
import { base64BlurPreload } from 'src/utils/images';

import { ClubPrices } from './ClubPrices';
import ImageCarousel from './ImageCarousel';
import { ImageSlide } from './ImageCarousel/ImageCarousel';

type ClubCardProps = {
  club: Club;
  prioritizeImageRendering?: boolean;
  className?: string;
  onHover?(uuid: string | null): void;
};

type Club = {
  city: string;
  country: string;
  currencyCode: string;
  holes: number[];
  deals: {
    hasActiveDeal?: boolean;
  };
  name: string;
  onlineBookingEnabled: boolean;
  photos: string[];
  placeholder: string;
  province: string;
  slug: string;
  uuid: string;
  weekdayPrice: number;
  weekendPrice: number;
};

export const ClubCard = ({
  club,
  prioritizeImageRendering = false,
  className,
  onHover,
}: ClubCardProps) => {
  const t = useT();
  const { locale, defaultLocale } = useRouter();
  const validLocale = locale || defaultLocale;
  const showTag = club.weekdayPrice || club.weekendPrice || club.onlineBookingEnabled;

  const handleMouseOver = () => {
    if (onHover) {
      onHover(club.uuid);
    }
  };

  const handleMouseOut = () => {
    if (onHover) {
      onHover(null);
    }
  };

  // Only display up to 5 pictures.
  const photos = club.photos.slice(0, 5);

  return (
    <div
      className={`group relative ${className}`}
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseLeave={handleMouseOut}
      onBlur={handleMouseOut}
    >
      <a
        href={`${process.env.NEXT_BASE_URL}/club/${club.slug}`}
        className="absolute inset-0 z-20 block h-full w-full scale-[1.06] rounded-md md:group-focus-within:!shadow-none md:group-hover:shadow-3 xl:group-hover:shadow-2"
      >
        <span className="sr-only">
          {t(`More about {clubName}`, {
            clubName: club.name,
          })}
        </span>
      </a>
      <div className="relative">
        {photos.length > 1 && (
          <ImageCarousel title={t('{clubName} pictures', { clubName: club.name })}>
            {photos.map((photo, index) => (
              <ImageSlide
                key={`club-${club.uuid}-slide-${photo}`}
                id={photo}
                reference={`club-${club.uuid}-slide-${photo}`}
              >
                <Image
                  priority={prioritizeImageRendering}
                  src={photo}
                  alt={t('{clubName} slide {index} of {total}', {
                    clubName: club.name,
                    index: index + 1,
                    total: photos.length,
                  })}
                  fill
                  sizes={`
                (max-width: ${tokens.breakpoints.xl}) 25vw,
                (max-width: ${tokens.breakpoints.lg}) 33vw,
                (min-width: ${tokens.breakpoints.md}) 50vw,
                100vw
                `}
                  placeholder="blur"
                  className="object-cover"
                  role="presentation"
                  blurDataURL={base64BlurPreload}
                />
              </ImageSlide>
            ))}
          </ImageCarousel>
        )}
        {photos.length === 1 && (
          <div className="relative block aspect-16/9 w-full shrink-0 overflow-hidden rounded-md">
            <Image
              priority={prioritizeImageRendering}
              className="object-cover"
              src={photos[0]}
              alt={t('{clubName} picture', { clubName: club.name })}
              fill
              sizes={`
            (max-width: ${tokens.breakpoints.xl}) 25vw,
            (max-width: ${tokens.breakpoints.lg}) 33vw,
            (min-width: ${tokens.breakpoints.md}) 50vw,
            100vw
            `}
              placeholder="blur"
              role="presentation"
              blurDataURL={base64BlurPreload}
            />
          </div>
        )}
        {(!photos || photos.length === 0) && (
          <div className="relative block aspect-16/9 w-full shrink-0 overflow-hidden rounded-md bg-primary-lighter">
            {club.placeholder && (
              <Image
                priority={prioritizeImageRendering}
                className="object-cover"
                src={club.placeholder}
                alt={t('{clubName} placeholder picture', { clubName: club.name })}
                fill
                sizes={`
            (max-width: ${tokens.breakpoints.xl}) 25vw,
            (max-width: ${tokens.breakpoints.lg}) 33vw,
            (min-width: ${tokens.breakpoints.md}) 50vw,
            100vw
            `}
                placeholder="blur"
                role="presentation"
                blurDataURL={base64BlurPreload}
              />
            )}
          </div>
        )}
        {club.deals.hasActiveDeal ? (
          <Tag className="absolute left-2 top-2 z-10 space-x-1" color="tertiary">
            <DealIcon width={12} height={12} />
            <span className="text-sm">{t('Deals available this week')}</span>
          </Tag>
        ) : null}
      </div>
      <div className="relative mt-2 flex items-start justify-between gap-2">
        <div>
          <h3 className="font-bold active:text-primary-darker">{club.name}</h3>
          <div className="mt-1 text-sm text-grey-600">
            <span className="capitalize">{club.city}</span>,{' '}
            <span className="capitalize">{club.province}</span>
          </div>
          {club.holes && (
            <div className="mt-2 flex items-center">
              {[...new Set(club.holes)]
                .sort((a, b) => a - b)
                .map((hole, index) => (
                  <Fragment key={hole}>
                    {index > 0 && <span className="relative mx-1 self-start leading-none">·</span>}
                    <span className="text-sm text-grey-700">
                      {hole} {t('holes')}
                    </span>
                  </Fragment>
                ))}
            </div>
          )}
        </div>
        {showTag && (
          <Tag className="leading-loose z-20 min-w-max flex-col space-y-1.5">
            {(club.weekdayPrice || club.weekendPrice) && (
              <>{validLocale ? <ClubPrices club={club} locale={validLocale} /> : '--'}</>
            )}
            {club.onlineBookingEnabled && (
              <span className="text-xs font-bold text-primary-light">{t('Online booking')}</span>
            )}
          </Tag>
        )}
      </div>
    </div>
  );
};
